
input:focus::-webkit-input-placeholder{color:transparent}input:focus:-moz-placeholder{color:transparent}input:focus::-moz-placeholder{color:transparent}input:focus:-ms-input-placeholder{color:transparent}textarea:focus::-webkit-input-placeholder{color:transparent}textarea:focus:-moz-placeholder{color:transparent}textarea:focus::-moz-placeholder{color:transparent}textarea:focus:-ms-input-placeholder{color:transparent}input::-webkit-input-placeholder{color:#fff}input:-moz-placeholder{color:#fff}input::-moz-placeholder{color:#fff}input:-ms-input-placeholder{color:#fff}textarea::-webkit-input-placeholder{color:#fff}textarea:-moz-placeholder{color:#fff}textarea::-moz-placeholder{color:#fff}textarea:-ms-input-placeholder{color:#fff}
.login{
background: #3e3e98;
    width: 100%;
    border: 0px;
    border-radius: 4px;
}
.limiter {
    margin: 0 auto;
    margin-left: -10px;
    margin-right: -10px;
}
.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
	background-image: url(/assets/images/pharmacy-bg.jpg);
}
.container-login100::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.9);
}
.wrap-login100 {
    width: 500px;
    border-radius: 10px;
    overflow: hidden;
    padding:35px 15px 35px;
    background: #3e3e98 !important;
}
.login100-form {
    width: 100%;
}
.login100-form-logo {
    font-size: 60px;
    color: #333;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
        border-radius: 5%;
    background-image: url(/assets/images/classic_app_icon.jpg);
    margin: 0 auto;
    background-size: cover;
    background-position: center;
}
.login100-form-title {
    font-size: 30px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    display: block;
}
.validate-input {
    position: relative;
}
.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid rgba(255,255,255,.24);
    margin-bottom: 30px;
}
.input100 {
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: 0 0;
    padding: 0 5px 0 38px;    border: 0px;
}
p {
    font-size: 14px;
    color: #ffffff;
    line-height: 1;
    text-transform: capitalize;
    padding-top: 10px;
}

.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}
.focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    background: #fff;
}
.focus-input100::after {
    font-size: 22px;
    color: #fff;
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 6px;
    left: 0;
    padding-left: 5px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}
.p-b-34 {
    padding-bottom: 34px;
}
.p-t-27 {
    padding-top: 27px;
}
.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.login100-form-btn {
    font-size: 16px;
    color: #555;
    line-height: 1.2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 120px;
    height: 50px;
    border-radius: 25px;
    background: #9152f8;
    background: -webkit-linear-gradient(bottom,#7579ff,#b224ef);
    background: -o-linear-gradient(bottom,#7579ff,#b224ef);
    background: -moz-linear-gradient(bottom,#7579ff,#b224ef);
    background: linear-gradient(bottom,#7579ff,#b224ef);
    position: relative;
    z-index: 1;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;    border: 0px;
}
.login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: #fff;
    top: 0;
    left: 0;
    opacity: 1;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}
.focus-input100 .fa {
    color: #fff;
    font-size: 21px;
    position: relative;
    top: 14px;
    left: 10px;
}