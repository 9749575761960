
@import "../../../public/assets/scss/slick.scss";
@import "../../../public/assets/scss/slick-theme.scss";
@import "../../../public/assets/scss/animate.scss";
@import "../../../public/assets/scss/themify.scss";
@import "../../../public/assets/scss/color1.scss";


._top_location{
    padding: 5px 0px;
}
._top_location .first_line {
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;    color: #ffffff !important;
}
._top_location .second_line {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 11px;
    margin-top: 0px;
    color: #ffffff;
}
._top_location .location-arrow {
    font-size: 13px;
    margin-left: 6px;
    color: #3b4094;
}
.location-arrow i {
    font-size: 20px !important;
    color: #ffffff !important;
}

._3JaG6 {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    -webkit-transition: -webkit-transform .3s ease-in;
    transition: -webkit-transform .3s ease-in;
    -o-transition: transform .3s ease-in;
    transition: transform .3s ease-in;
    transition: transform .3s ease-in, -webkit-transform .3s ease-in;
    -webkit-animation: _2RsLF .3s ease-in;
    animation: _2RsLF .3s ease-in;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
._2Rt_0 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    z-index: 120;
    background-color: #fff;
    height: 50px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 -1px 1px 0 rgba(0,0,0,.1);
    box-shadow: 0 -1px 1px 0 rgba(0,0,0,.1);
}
._29wpS {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
}
._2n_pK {
    color: #fc8019;
}

._1D7fu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: .83rem;
    width: 25%;
    font-weight: 500;
    height: inherit;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #535665;
    text-decoration: none;
    overflow: hidden;
    position: relative;
}
.bottom-menu span{
    font-size: 12px;
}
.bottom-icon img{
    height: 25px;
}
.bottom-icon .fa {
    font-size: 18px;
    color: #a5a5a5;
}
.bottom-menu .active span,.bottom-menu .active .fa{
    color: #3b4094;
}
.slick-slide img {
    width: 100%;
}
.main-menu .menu-left .navbar {
    padding: 5px 5px 5px 0 !important;
}
body {
    padding: 0px 10px;    background: #f2f2f2 !important;
}
.margin-pd{
    margin: 0px -10px;
}
.pd-10{
  padding:0px 10px;
}
.empty-cart{
    text-align: center;
}
.empty-cart img{
    width: 100px;
    float: none;
    margin: 0px auto;
    margin-top: 40px;
    opacity: 0.5;
}
.empty-cart div{
    width: 100%;
    font-size: 15px;
    font-weight: 600;
}
.container {
    padding-right: 10px;
    padding-left: 10px;
}
._3C_gI {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
    padding: 16px;
    background-color: #fff;
    position: relative;
    margin: 0 10px 10px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.09);
    border-radius: 6px;
}
.Cxph3 {
    width: 10%;
    margin-right: 16px;
}
.Cxph3 img {
    height: 46px;
    width: 46px;
}
.NXGft {
    margin: 0 20px;
    width: 80%;
    padding: 5px 0;
    max-width: 250px;
}
._3oKxo, ._14v1V {
    font-weight: 500;
    text-align: left;
}
._14v1V {
    font-size: 14px;
    color: #3e3e98;
}
._3oKxo {
    font-size: 12px;
    color: #9b9b9b;
}
._3oKxo, ._14v1V {
    font-weight: 500;
    text-align: left;
}
._3tKCQ {
    padding: 10px 0;
    height: auto;
    width: 30px;
    position: absolute;
    right: 0%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);    text-align: right;
}
._3tKCQ img {
    max-height: 100%;
    max-width: 100%;
}
section, .section-t-space {
    background: #f2f2f2;
	padding-top: 15px !important;
}
.loader-wrapper {
    top: 51px;
    left: 0px;
    width: 100%;
}
.timeline-item {
    background: #fff;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 0px;
    padding: 12px;

    margin: 0 auto;
    min-height: 100px;margin-bottom: 10px;
}
@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.animated-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}
.background-masker {
    background: #fff;
    position: absolute;
}

/* Every thing below this is just positioning */

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
    top: 0;
    left: 40px;
    right: 0;
    height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
    top: 10px;
    left: 40px;
    height: 8px;
    width: 10px;
}

.background-masker.header-bottom {
    top: 18px;
    height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
    top: 24px;
    height: 6px;
}


.background-masker.header-right,
.background-masker.subheader-right {
    width: auto;
    left: 300px;
    right: 0;
}

.background-masker.subheader-right {
    left: 230px;
}

.background-masker.subheader-bottom {
    top: 30px;
    height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
    top: 40px;
    left: 0;
    right: 0;
    height: 6px;
}

.background-masker.content-top {
    height:20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end{
    width: auto;
    left: 380px;
    right: 0;
    top: 60px;
    height: 8px;
}

.background-masker.content-second-line  {
    top: 68px;
}

.background-masker.content-second-end {
    left: 420px;
    top: 74px;
}

.background-masker.content-third-line {
    top: 82px;
}

.background-masker.content-third-end {
    left: 300px;
    top: 88px;
}



.my-plan .col-lg-12{
    padding-right: 5px;
    padding-left: 5px;
}
.my-plan{
    margin: 0px -3px;
}
.my-plan .dashboard-right .dashboard {
    padding: 15px;
    background: #fff;
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    margin-top: -15px;
    border: 0px solid #ddd;
}
.my-plan .dashboard .box .box-title {
    border-bottom: 0px solid #ddd;
    display: inline-block;
    margin-bottom: 0px;
    padding: 10px 0;
    position: relative;
    width: 100%;
}
.my-plan .dashboard-right .dashboard .page-title h2 {
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 0px;
    text-transform: capitalize;
    font-weight: 600;
    color: #3e3e98;
}
.dashboard .box-head h2 {
    font-size: 18px !important;
    margin: 20px 0 0;
    text-transform: capitalize;
    color: #ff4c3b;
    font-weight: 500;
}

.dashboard .box-head span{
 font-size:12px;
}
.dashboard .box .box-title h3 {
    font-size: 13px;
    margin: 0;
    text-transform: capitalize;
    color: #5050a1;
    margin-top: 10px;
}
.my-plan .dashboard .box .box-title > a {
    position: absolute;
    right: 0;
    top: 12px;
    color: #fff;
    border: 2px solid #3e3e98;
    border-radius: 5px;
    padding: 4px 12px;
    background: #3e3e98;
}
header{
    position: fixed;
    top: 0px;
    z-index: 9999;
    width: 100%;
	height: 52px;
    border-bottom: 1px solid rgba(40,44,63,.1);
    background: #3e3e98;
}
.slick-slider {
    margin-top: 49px;
}
._3lldG {
    height: 100%;
    background: #f4f4f5;
    color: #282c3f;
    padding-top: 45px;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
}
._1iY8Y {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    contain: content;
}
._2u0oK {
    padding: 0;
    background: #fff;
    height: 52px;
    overflow: hidden;
    z-index: 9999;
    border-bottom: 1px solid rgba(40,44,63,.2);
}
._3TzfO {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 52px;
    width: 100%;
    padding-right: 16px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
._2QN4q {
    padding: 16px 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    display: inline-block;
    line-height: 0;
}
._2QN4q img{
    height:22px;
}
.logo-img{
	height:40px !important;
}
._1leRb {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
    padding-left: 4px;
}
._3B7Eo {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    height: 100%;
    outline: 0;
    border: none;
    overflow: hidden;
    font-family: inherit;
    color: inherit;
    background: inherit;
    vertical-align: middle;
}
.b5NJg {
    color: #686b78;
    font-size: 18px;
    height: 30px;
}
.b5NJg img{
    height: 20px;
}
._3mMtt {
    position: relative;
    background: #fff;
    min-height: 100%;
}
._1qG6k {
    position: relative;
    overflow: hidden;
}
._3ce3U {
    padding: 20px 15px;
    display: table;
    table-layout: fixed;
    width: 100%;
}
._1nPUR {
    font-size: 20px;
    vertical-align: middle;
    display: table-cell;
    width: 24px;
    text-align: center;
}
._1nPUR img{
height: 21px;
}
._3qV1m {
    padding-left: 15px;
    vertical-align: middle;
    display: table-cell;
}
._1kMGJ {
    color: #282c3f;
    margin-bottom: 4px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
}
._2cGgB {
    color: #686b78;
    font-size: 12px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
._1qG6k:first-child:after {
    border-width: 1px;
}
._1qG6k:after {
    content: '';
    display: block;
    border-top: 1px solid #e9e9eb;
    position: relative;
    margin-left: 54px;
}
._3AFC5 {
    min-height: 100%;
    background-color: #f4f4f5;
    padding-bottom: 10px;
}
._1bhn9 {
    padding-top: 45px;
}
._2czoF {
    margin-top: 7px;
    padding: 10px 15px;
    background-color: #fff;
}
._2czoF ._2GMgT {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
._2czoF ._2_-Em {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: #525252;
}
._2czoF ._1KevM {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #93959f;
    margin-top: 0px;
    font-size: 13px;
}
.NeDfT:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}
._3tVyU ._39p-f._3lD0G {
    display: block;
    -webkit-animation: _3Dwbz .35s ease-in;
    animation: _3Dwbz .35s ease-in;
}
._1Xqx7 {
    position: relative;
}
._2AdD- {
    padding: 14px 16px 16px;
}
._2jjDQ {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    overflow: hidden;    margin: 8px 8px;
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.09);
    border-radius: 4px;
}
._2gQbw {
    width: 100%;
}
.Zo88I {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}
.Zo88I img{
    height: 21px;
}
._33X77 {
    margin-right: 16px;
    font-size: 1.1rem;
    width: 16px;
    text-align: center;
}
._2N4la {
    margin-top: 10px;
}
._1q4Zi {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 16px;
    background-color: #fff;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-transform: uppercase;
}
#geomap{width: 100%; height: 370px;}
._1knFu {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
}
._2eSrj {
    height: 38px;
    line-height: 18px;
    padding: 10px 0;
    color: #282c3f;
    font-weight: 400;
    border: 0;
    border-bottom: 1px solid #e9e9eb;
    width: 100%;
    outline: 0;
    background: 0 0;
    caret-color: #fc8019;
    position: relative;    font-size: 14px;
}
.btn-primary2 {
    color: #fff;
    padding: 12px 20px;
    margin-bottom: 20px;
}
.btn1{
border-color: #3d3e98;
background:#3d3e98;
}
.btn2{
border-color: #eb3438;
background:#eb3438;
}
._1jnwd {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
}
.anfG9 {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
._2O4ey {
    text-transform: uppercase;
}
.pac-container {
    z-index: 1000;
    border-radius: 2px;
    border-top: 0px solid #d9d9d9;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.17);
    z-index: 9999;
	top: 52px !important;
    width: 100% !important;
    left: 0px !important;
}
.pac-item {
    padding: 10px 10px;
}
.pac-icon-marker {
    background-image: url(https://kapso.in/classic/assets/images/icon/location.svg) !important;
	    background-size: cover;
    opacity: 0.7;
    width: 20px;
    height: 20px;

    background-position: center;
}
.page_title{

}
.page_head{
    border-bottom: 1px solid rgba(40, 44, 63, 0.1);
    background: #3e3e98;
    color: #fff;
}
.card {
    background-color: #f6fafa;
}
.gradient-success {
    background-image: -moz-linear-gradient(45deg, #66cd2e, #b2f755);
    background-image: -webkit-gradient(45deg, linear, 0% 0%, 0% 100%, from(#66cd2e), to(#b2f755));
    background-image: -webkit-linear-gradient(45deg, #66cd2e, #b2f755);
    background-image: -o-linear-gradient(45deg, #66cd2e, #b2f755);
}
.mb-3, .my-3 {
    margin-bottom: 10px !important;
}
.rounded-0 {
    border-radius: 0 !important;
}
.border-0 {
    border: 0 !important;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: .25rem;
}
.pl-2, .px-2 {
    padding-left: 5px !important;    padding-right: 10px;
}
.pr-2, .px-2 {
    padding-right: 5px !important;    padding-left: 10px;
}
.card .card-body {
    position: relative;
    padding: 15px;
}
.f-light {
    font-weight: 300 !important;
}
.mb-0, .my-0 {
    margin-bottom: 0 !important;
}

.gradient-primary {
   background:#fff;    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.09);
    border-radius: 6px;
}
.dashboard{
	background:transparent;
}

.dashboard .row{
    margin: 0px;
}
.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.dashboard .card {
        border-radius: 4px !important;
}
.dashboard-img{
	text-align:center;
}
.dashboard-img img{
	height:45px;
}
.media-body{
text-align:center;
}
.gradient-primary p {
    color: #3e3e98;
}
.sub-title {
    padding: 0px 0;
    border-bottom: 1px solid #d2d8e1;
    margin-bottom: 0px;
    line-height: 30px;
    font-size: 12px !important;
    color: #0000008a;
}
.account_info .icon{
	text-align: center;
}

.account_info .icon img{
	height:45px;
}
.account_info p {
    font-size: 14px;
    color: #3e3e98;
    font-weight: 400;    padding-top: 5px;
}
.account_info .card .card-body {
    padding: 4px 10px !important;
    padding-bottom: 5px !important;
}
.grey{
    color: #404040 !important;
}
.status-red{
    color: red !important;
}
.status{
 color: green !important;
}
.bold{
font-weight:600 !important;
}
.bg-purple {
    background-color: #f5f5f5b8 !important;
}
.page-title-row {
    padding: 10px 0;    padding-bottom: 0px;
}
.page-title-row .page-title {
    font-size: 14px;
    font-weight: 600;
    color: #20263e;
    margin-bottom: 0;
}
.page-title-row .page-title+p {
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 0px;
    padding-top: 5px;
}
.account_details .page-title-row {
    padding: 4px 0;
    padding-bottom: 0px;
    border-top: 2px dotted #7b7b7b87;
}
.text-blue {
    color: #3e3e98 !important;
}
.account-usage{
    text-align: center;
    text-transform: uppercase;
    padding-top: 10px !important;    margin-bottom: 0px;
    color: #808080 !important;
	}

#chart_gb{
width:100%;
}
#chart_days{
width:100%;
}
#chart_gb text{
    display: none !important;
}

#chart_days text {
    display: none !important;
}
.google-visualization-tooltip {
    display: none !important;
}
.chart_gb_label
{
    position: absolute;
    left: 25%;
    top: 32%;
    text-align: center;
    font-size: 14px;
}
._32sRE a{
    color: #212529;
}
.chart_days_label
{
    position: absolute;
    top: 33%;
    font-size: 14px;
    text-align: center;
    width: 91%;
}
.chart-red{
 color: red !important;
}
.speed span{
    font-size: 11px;
    color: #949494;
}
.account_details .table {
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 0px;
}
.account_details .right{
	text-align:right;
    font-weight: 600;
}

button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
    color: #fff;
}
.login100-form-btn:focus {

    border: 0px;
}
.alert {
    max-width: 100% !important;
    padding: 4px 5px;
    text-align: center;    margin-bottom: 0px;
}
#alert{
    height: 31px;
 }



/*renew_plan starts */
.renew-tab .nav-material.nav-tabs .nav-item {
    width: 50%;
}

.react-tabs__tab--selected {
    background: #3e3e98 !important;
    border-color: #aaa !important;
    color: #fff !important;
}

.react-tabs__tab--selected .nav-link {
    color: #fff !important;
}


.renew-tab .nav-material.nav-tabs .nav-link {
   color: #fff !important;
}

.renew-tab .nav-material.nav-tabs .nav-item {
    margin-bottom: -2px;
    background: #3e3e98 !important;
    color: #fff !important;
    border-bottom: 2px solid #3e3e98;
	    font-size: 12px;
}

.renew-tab .tab-content .tab-m-header{
	background: #3636c5;
    padding: 20px 25px;
    text-align: center;
}

.renew-tab .tab-content .tab-pane  .tab-m-header p{
    color: #fff;
    font-size: 18px;
    padding: 8px 0px;
}
.renew-tab .tab-content .tab-pane  .tab-m-header span{
    color: #fff;
}
.renew-tab .tab-content .tab-pane{
    margin-top: 0px !important;
}

.table-renew {
	  table-layout: fixed;
}
.table-renew .tr-head{
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}
.table-renew th, .table-renew td {
    width: 100%;
}
.table-renew td {
 text-align:right
}

.color-white{color:#fff}

.renew-form{
   margin-top: 10px;
   padding: 0px 35px;
}
.renew-form button{
  background: #ff4c3b;
    border-color: #ff4c3b;
}
.renew-tab .tab-content .nav-tabs{
    position: fixed;
    top: 52px;
    width: 100%;
    z-index: 9999;
}
.border-blue{    border-bottom: 1px solid #3e3e98;}

 .m-plan .col-12, .m-plan .col-sm-12, .m-plan .col-md-12, .m-plan .col-lg-12, .m-plan .col-xl-12{ padding:0px;}

.account_info .row{ margin: 0px;}
.m-plan .container, .account_info .container{ padding: 0px;}
.m-plan .container-fluid, .account_info .container-fluid{ padding: 0px;}
 .pt-20{ padding-top:20px;}
/*renew_plan ends */

/*form float label starts */
.form-group.float-label {
	margin-bottom: 20px;
	position: relative;
	padding-top: 20px
}

.form-group.float-label>button.overlay {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 2
}

.form-group.float-label .form-control[type="date"] {
	padding: 0.313rem 0rem
}

.form-group.float-label label {
	color: #fff;
	-moz-transition: ease all .2s;
	-o-transition: ease all .2s;
	-webkit-transition: ease all .2s;
	transition: ease all .2s;
	line-height: 20px;
	position: absolute;
	top: 27px;
	left: 0;
	font-size: 16px;
	opacity: 1;
	z-index: 0
}

.form-group.float-label .form-control {
	position: relative;
	z-index: 1;
	background: none;
	padding: 0.313rem 0rem;
	border-width: 0 0 1px 0;
	border-radius: 0;
	-webkkit-borde-radius: 0;
	font-size: 16px;
	-moz-transition: ease all .5s;
	-o-transition: ease all .5s;
	-webkit-transition: ease all .5s;
	transition: ease all .5s;
    border-bottom: 2px solid rgba(255, 255, 255, 0.24);
	color: #fff;
}

.form-group.float-label .form-control.active,
.form-group.float-label .form-control:focus {
	outline: none;
	-webkit-outline: none;
	box-shadow: none;
	-webkit-box-shadow: none
}

.form-group.float-label .form-control.active+label,
.form-group.float-label .form-control:focus+label {
	top: 0;
	left: 0;
	font-size: 13px;
	opacity: 1
}

.form-group.float-label .form-control:focus {
    border-bottom: 2px solid rgba(255, 255, 255, 0.24);
    color: #fff;
}

.form-group.float-label .form-control:focus+label {
	color: #fff
}

.form-group.float-label .form-control::placeholder {
	color: rgba(133, 150, 174, 0.5)
}

.form-group.float-label .input-group {
	position: initial
}

.form-group.float-label .input-group label {
	top: 0;
	left: 0;
	font-size: 13px;
	opacity: 1
}

.form-group.float-label .input-group .input-group-text {
	background: none;
	border-width: 0 0 1px 0;
	border-color: rgba(133, 150, 174, 0.5);
	border-radius: 0;
	max-height: 35px;
	color: #20263e
}

.m-b-10{ margin-bottom: 10px;}

.m-form .form-group.float-label label{ color:#000;}
.m-form .form-group.float-label .form-control{ color:#000; border-bottom: 2px solid #9E9E9E;}
.form-mlr{margin: 0px 15px 20px 15px;}
/*form float label ends */


/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

/* Button style */



/* Plan Card starts */
.table-plan {
	table-layout: fixed;
}

.table-plan th {
  width: 50%;
  text-align:left;
  vertical-align: middle;

}
.table-plan td {
   width: 100%;
   text-align:right;
   vertical-align: middle;
}

 .plans_card{
	border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: rgba(223,225,229,0);
    box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
    margin: 15px 15px 0px 15px;
   }

 .plans_card .plan-title{ font-weight: 600;}
 .plans_card .table-plan td{ padding: 5px;}
 .plans_card .table-plan button{ margin-bottom: 0px !important;margin-top: 10px;}

  .plans_card .card .card-body { padding:12px 12px;}

/* Plan Card ends */

/* modal starts */
.modal-dialog {
    margin: 0px !important;
    top: 10%;
    padding: 0px 20px 0px 15px;
}
.modal-open {
    padding-right: 10 !important;
}
/* modal ends */


#plan_list{
	text-align: center;
}
#loader{
  padding:20px 0px;
}
.mt-20{ margin-top: 20px !important;}

.tickets{
    padding-top: 5px !important;
}

.tickets .account-usage{
	padding-bottom: 5px;
    margin-top: 5px;
}
.tickets .ticket-subtitle{
  text-align: center;
    padding: 0px !important;
    margin-bottom: 0px;
    color: #808080 !important;
    font-size: 14px;
	}	
.tickets th { width: 85%;}	
.ticket2 th {width: 60%;}
.pt-25{ padding-top: 25px !important;}
.tickets th { width: 85%;}
.ticket2 th {width: 60%;}

.table-purchase{  
position: fixed;
    top: 94px;
    width: 100%;
    z-index: 9999;
    background: #ffffff;
    text-align: center;
    font-size: 12px;
    border-color: rgba(223, 225, 229, 0);
    box-shadow: 0 1px 4px 0 rgba(32, 33, 36, 0.28);
}

.purchase-tab .tab-content .tab-pane{
    margin-top: 55px !important;
}

.table-purchase th, .table-purchase td {
    padding: 5px;
}

.table-purchase p {
    color: #000;
    padding: 10px 0px !important;
}

.no-pad{ padding: 0px !important;}
.deafult-icon{  
  text-align: center;
  font-size: 60px;
  opacity: 0.5;
}

.bg-white{ background: #fff;}

.no-marg{ margin: 0px;}

.tab-product .nav-material.nav-tabs .nav-item .nav-link {
    padding: 10px 4px;
}


.mt-120 {
    margin-top: 120px;
}

.home-tab{
    padding-top: 5px !important;
    margin: 0px -5px;
}
.home-tab .row {
 margin-right: 0px; 
 margin-left: 0px;
}
.home-tab .pr-2,.home-tab .px-2 {
    padding-right: 2px !important;
    padding-left: 2px;
    margin-bottom: 5px;
}
.home-tab  .card p {
    font-size: 13px;
    text-transform: inherit;
}
.home-tab ._3C_gI {
    margin: 0px;
    border-radius: 4px;
}
.home-tab .card {
    border-radius: 4px;
}
.home-tab .card .card-body {
    position: relative;
    padding: 15px 5px;
    height: 100px;
}
.swiper-container {
    margin-top: 4px;
}

.swiper-img{
width:100%;
}
.swiper-left-nav {
    -webkit-transform: translateY(0%) rotate(180deg);
    transform: translateY(0%) rotate(180deg);
    left: 0;
}
.swiper-slide {
min-width: 300px;
}

.wallet {
    margin-top: 55px;
    background: #fff;
    padding: 10px 0px 10px 10px;
    font-weight: 500;
}
.wallet table{
    width: 100%;
}
.wallet img{
    height:20px;
}

.wallet span{
    
}
.swiper-button-next,.swiper-button-prev {
    background: none;
}
.coming-soon{
    padding-top: 45px;
}
.coming-soon img{
    width: 100%;
}
.wallet-pd{
	margin: 0px -5px;
}
.header-logo{
    float: left;
    height: 40px;
    margin-top: 6px;
    margin-right: 8px;
}

.wallet-right{
    text-align: right;
    padding-right: 10px;
}
.changu {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: auto !important;
    height: 72px !important;
}
.chat_order_btn{
    float: right;
    border: 1px solid #3e3e98;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    background: #3e3e98;
    color: #fff;
    font-size: 13px;
    margin-top: 5px;
}
.mck-sidebox-launcher {
         display:none;
         }
		 .mck-box .move-right {
    color: white;
    margin-right: 10px;
}
div:focus {
    outline: -webkit-focus-ring-color auto 0px;
}
.mck-mic-btn{
    -webkit-appearance: initial !important;
}
.mck-box-top {
    background: #3e3e98;
}
#mck-tab-title{
    color: #fff;
}
.res-bg{
	width:100%;
    margin-top: -8px;height: 140px;
    background-size: cover;
}
.res-bg img{
	width:100%;
}
.res-details-div .panel {
    padding: 0px 5px;
}
.no-margin{
margin : 0;
}
.home-left-mar{
    margin-left: -10px;
}
.home-lft-mar{
	margin-left: 10px;

}
#sticky{
display : none;
}
.footer-light{
display : none;
}
.pt-70{
padding-top : 70px !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #fff;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  line-height: 3;
  background: #fff;
  overflow: hidden;
  border-radius: .25em;
}
select {
  flex: 1;
  padding: 0 .5em;
  color: #fff;
  cursor: pointer;
}
/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}
/* Transition */
.select:hover::after {
  color: #3e3e98;
}
.select,.select select,.select option{
color : #3e3e98 !important;
border: 1px solid #3e3e98;
}
col-white{
color : #3e3e98 !important;
}


.pharmacy .nav-material.nav-tabs .nav-item {
    text-align: center;
}
.pharmacy .nav-material.nav-tabs .nav-item {
    border-bottom: 0px solid #3e3e98;
}
.pharmacy .nav-tabs>li.active>a, .pharmacy .nav-tabs>li.active>a:focus, .pharmacy .nav-tabs>li.active>a:hover {
    color: #fff;
    background-color: #3e3e98;
    border: 0px solid #ddd;
    border-radius: 0px;
    border-bottom: 2px solid #ff4c3b !important;
}
.pharmacy .nav-tabs>li>a {
    border: 0px solid transparent;
    border-radius: 0px;    color: #fff;
}
.pharmacy .tab-product .nav-material.nav-tabs {
    border-bottom: 3px solid #3e3e98;
}


.mck-sidebox .mck-box-backdrop.in {
    width: 100% !important;
    right: 0 !important;
    left: auto !important;
}
.mck-box .mck-box-backdrop.in {
    filter: alpha(opacity = 50) !important;
    opacity: .5 !important;
}
.mck-box .mck-box-backdrop {
    background: transparent !important;
}
.mck-sidebox.mck-fade.in {
    width: 100% !important;    display: block !important;
}
.mck-box.mck-fade.in {
    opacity: 1 !important;
    background: transparent !important;
    width: 100% !important;
}
.mck-box-open .mck-box, .mck-box-open .mck-box {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}
.mck-fade {
    opacity: 1 !important;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear;
}
.mck-sidebox {
border: 0 !important;
    padding-left: 0px !important;
    z-index: 99 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    line-height: 18px !important;
    min-width: 310px !important;
    display: block !important;
    width: 100% !important;
    position: fixed !important;
    top: auto !important;
    height: 100% !important;
    left: 0px !important;
	right:auto !important;
}
.mck-box .mck-box-sm {
   -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
}
.mck-box-content.vis {
    display: table !important;
}
.mck-sidebox .mck-box-content {
    height: 100%;
}
.mck-box-content {
    border: 0;
    display: table;
    width: 100%;
    border-spacing: 0;
    outline: 0;
    table-layout: fixed;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.mck-box-top {
    margin-top: 91px;
}
.my_profile .mck-sidebox,.my_profile #mck-sidebox{
	display: none !important;
}
.mck-sidebox .mck-box-title {
    color: #fff;
}
.del-time{
width : 47%;
margin-right:5px;
float: left;
margin-bottom:20px;
}
.sideBar-avatar {
    text-align: left;
    padding: 0 8px !important;
}
.sideBar-name {
    padding: 10px 5px !important;
}

.avatar-icon img {
height: 40px;
    width: 40px;
    border-radius: 51px;
}
.name-meta {
   text-transform: capitalize;
    font-size: 14px;
}
.name-msg {
    font-size: 11px;
    color: #909090;
}
.time-meta {
    text-align: right;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: bottom;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.pull-right {
    float: right!important;
}

.sideBar-body {
    height: 80px;
    width: 100%;
}
.sideBar-body {
    position: relative;
    padding: 10px !important;
    border-bottom: 1px solid #f7f7f7;
    height: 65px;
    margin: 0 !important;
    cursor: pointer;    background-color: #fff;
}
.mg_10 {
    margin-right: -10px !important;
}
.sideBar-avatar {
    text-align: left;
    padding: 0 0px !important;
}
.sideBar-main{
    padding-right: 0px !important;
}
.sideBar-main .sideBar-name{
    padding: 0px !important;
}
.sideBar-time{
    padding: 0px !important;
    height: 40px;
}
.chat_count{
font-size: 9px;
    background: #40d240;
    color: #fff;
    width: 19px;
    height: 19px;
    padding: 4px;
    border-radius: 50%;
    text-align: center;
}
.rcw-conversation-container .rcw-header {
    margin-top: 110px; padding: 0px 0 0px !important;
}
.rcw-conversation-container .rcw-close-button {
    display: none !important;
}
.rcw-conversation-container .rcw-title {
    font-size: 14px !important;
    text-align: left !important;
}
.rcw-conversation-container .rcw-header {
    text-align: left !important;
    padding-left: 15px !important;
}
.rcw-header span{
font-size: 10px;
}
.rcw-conversation-container .rcw-title {
    padding: 0 0 5px !important;
}

.rcw-new-message::-webkit-input-placeholder { /* Edge */
  color: #4a4a4a;
}

.rcw-new-message:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #4a4a4a;
}

.rcw-new-message::placeholder {
  color: #4a4a4a;
}
.rcw-client, .rcw-response {
    border-radius: 5px;
    padding: 10px !important;
}
.rcw-client {
    background-color: #94C2ED !important;
}
.rcw-conversation-container {
    background-position: center !important;
    background-attachment: fixed !important;
    background-size: contain !important;
}
.rcw-messages-container {
    background-color: #ffffffe8 !important;
}
.rcw-sender {
    border-top: 1px solid #a9a9a9 !important;
}
.rcw-response {
    background-color: #86BB71 !important;
}
.rcw-client, .rcw-response {
    padding: 1px 8px !important;
    border-radius: 5px !important;
}
.from_name{
    top: 90px !important;
}
.from_name li{
    width: 100% !important;
    padding: 10px !important;
	background: #F2F5F8 !important;
	height: 38px;
}
.rcw-client:after {
    content: '';
    /* position: absolute; */
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid #94c2ed;
    right: 0px;
    margin-top: -12px;
    margin-right: -14px;
    float: right;
}
.rcw-response:after {
    content: '';
    /* position: absolute; */
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 8px solid #86bb71;
    left: 0px;
    margin-top: -12px;
    margin-left: -14px;
    float: left;
}
.chat-file{
    width: 100%;
}
.rcw-snippet-details {
    border-left: 0px solid #35e65d !important;
    margin-top: 5px;
    padding-left: 0px !important;margin-bottom: 5px;
}
.width100{
    width: 100%;
}
.rcw-left-time{
    font-size: 10px;
    margin-top: -8px;
    color: #a7a7a7;
    font-weight: 200;
    text-align: left;
}

.rcw-right-time{
    font-size: 10px;
    margin-top: -8px;
    color: #a7a7a7;
    font-weight: 200;
    text-align: right;
}
#file_upload{
	display:none;
}
.file_attach{
	font-size: 20px;
	font-size: 20px;
    position: fixed;
    bottom: 2px;
    z-index: 9999;
    right: 52px;
    color: #ababab;
}
.chat-popup {
	display:none;
    position: fixed;
    bottom: 45px;
    right: 0px;
    border: 0px solid #f1f1f1;
    z-index: 999999;
    width: 100%;
    background: #f3f6f8;
}
.form-container {
    padding: 5px 10px;
    text-align: center;
}
.form-container .cancel {
    color: red;
}
.form-container .btn {
    color: #4CAF50;
    padding: 4px 14px;
    border: none;
    margin-bottom: 0px;
    background: #fff;
    border: 1px solid #d4d4d4;
    text-transform: capitalize;
    border-radius: 3px;
    margin-right: 5px;
}
.pres-img {
    padding-bottom: 6px !important;
}
.pres-img .rcw-snippet {
    border-radius: 0px !important;
    padding: 0px !important;
}
.form-container img{
width:100%;
}
.uploading{
	display:none;
	height: auto;
    position: fixed;
    bottom: 50px;
    z-index: 99999998;
    width: 98%;
    left: 4px;
}
.uploading img{
	width: 100%;
    height: 8px;
    opacity: 1;
}
.no-msg{
    width: 100%;
    padding: 50px;
}
.ant-upload-list {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    zoom: 1;
}
.ant-upload-list-picture .ant-upload-list-item-info, .ant-upload-list-picture-card .ant-upload-list-item-info {
    padding: 0;
}
.ant-upload-list-item-info {
    height: 100%;
}
.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 66px;
    padding: 8px;
    border-radius: 4px;
}
.ant-progress-outer {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    padding-right: 0;
}
.ant-progress-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: #f5f5f5;
    border-radius: 100px;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 48px;
    height: 48px;
    font-size: 26px;
    line-height: 54px;
    text-align: center;
    opacity: 1;
}
.ant-upload-list-item-info>span {
    display: block;
    width: 100%;
    height: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 48px;
    height: 48px;
    overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-image, .ant-upload-list-picture-card .ant-upload-list-item-image {
    max-width: 100%;
}

.uploading .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
    border: 1px dashed #d9d9d9;    background: #f4f7f9;
}
.uploading .ant-upload-list-picture .ant-upload-list-item-name-icon-count-2, .ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
    padding-right: 10px;
    padding-top: 22px;
    display: block;
    padding-left: 60px;
}
.ant-upload-list-item-name {
    width: 100%;
}
.rcw-messages-container {
    background: url(https://classicxtra.com/bucket/image/chat_bg.png);
}
.rcw-client {
    background-color: #ffffff !important;
    border: 1px solid #3e3e9866;
}
.rcw-client, .rcw-response {
    padding: 1px 5px !important;
}
.chat_box .modal {
    z-index: 100000;
}
.zoom_image{
	width: 100%;
}
.ant-modal-body {
    padding: 0px !important;
}
.ant-modal-header {
    display: none;
}
.ant-modal-footer {
    display: none;
}
.rcw-message-text p {
    color: #5f5f5f;
}
.rcw-response .rcw-message-text p{
    color: #fff;
}

.chat_box .modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
 z-index: 100000;
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color:rgba(0, 0, 0, 0.57)
}
.chat_box .modal-content {
  margin: auto;
  display: block;
    padding: 0px;
    width: 95%;
  max-width: 700px;touch-action: auto;
}
.chat_box .modal-content, .close{  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}
@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}
.chat_box .close {
	position: absolute;
    top: 75px;
    right: 10px;
    color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0.7;
}
.chat_box .close:hover,
.chat_box .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px){
  .chat_box .modal-content {
       padding: 0px;
    width: 95%;
  }
}













