.restaurant-search{
	background: #3636c5;
    padding: 5px 13px;
    text-align: center;
    padding-top: 70px;
    position: fixed;
    top: -5px;
    width: 100%;
    z-index: 888;
    text-align: center;
    font-size: 12px;
    border-color: rgba(223, 225, 229, 0);
    box-shadow: 0 1px 4px 0 rgba(32, 33, 36, 0.28);
}

.mt-135{ margin-top: 135px;}
.mt-45 {margin-top: 45px;}
.rest-form button i{  
     color: grey;
}
.rest-form input, .rest-form button{  
   background: #f7f7f7; 
   border: 0px solid #ced4da;
}
.rest-form input{
  font-size: 14px;
  padding: 10px 10px;
}


.rest-form  .btn-outline-secondary:hover, .rest-form   .btn-outline-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #f7f7f7; 
	border: none;
}

.rest-form .btn-outline-secondary:not(:disabled):not(.disabled):active:focus{
    box-shadow: none;	
}
.nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.restro ._3oKxo{
	text-overflow: ellipsis;
    overflow: hidden;
    width: 204px;
    white-space: nowrap;
    font-size: 13px;
}
.restro ._14v1V{ 
    color: #454950;
    font-size: 17px;
    font-weight: 600;
}
.order_offer{
	padding: 4px 10px;
    background: green;
    color: white;
    width: max-content;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 400;
	
}
.offer_padding{
	 padding: 70px 4px;
}



.restro .res-img{
	width: 40%;
    margin-right: 10px;
}

.restro .res-info {
    margin: 0 5px;
    width: 60%;
    padding: 5px 0;
}

.restro  .res-img img{
    height: auto;
    width: 150px;	
}

.restro .order_details{	
    color: #454950; 
	font-size: 14px;
}

.restro .nowrap{
    padding-right: 0px;	
}
.restro .order_details .bullet{ 
   font-size: 6px;
    vertical-align: middle;
	padding:0px 10px;
}

.restro .res-img .bgimg{
    position: relative;
    width: 100%;
    height: 100px;
    background-color: rgba(40, 44, 63, 0.05);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.restro .res-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    padding: 10px;
    background-color: #fff;
    position: relative;
    margin: 0 10px 10px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.09);
    border-radius: 6px;
}
.restro .res-details-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    padding: 0px;
    background-color: #fff;
    position: relative;
    margin: 0 0px 10px;
    border-radius: 0px;
}
.restro .res-details-info {
    margin: 0 5px;
    width: 100%;
    padding: 5px 0;
}
.center{
	text-align:center;
}
.width{
	width:100% !important;
}
.col-md-6{
	width:50% !important;
	float:left;
}
.col-md-4{
	width:33.33% !important;
	float:left;
}
.col-md-3{
	width:25% !important;
	float:left;
}
.col-md-9{
	width:75% !important;
	float:left;
}
.col-md-2{
	width:16.67% !important;
	float:left;
}
.col-md-1{
	width:8.33% !important;
	float:left;
}
.col-md-10{
	width:83.33% !important;
	float:left;
}
.col-md-11{
	width:91.66% !important;
	float:left;
}
.col-md-9{
	width:75% !important;

}
.col-md-12{
	width:100% !important;

}
.order-min-height{
	min-height: 35px;
}
.track-min-height{
	min-height: 60px;
}
.track-min-height-1{
	min-height: 30px;
}
.order-img{
	width:13px;
	margin-right:4px;
}
.right{
	text-align: right;
}
.no-pad{
	padding-left: 0;
    padding-right: 0;
}
.top-border{
	border-top:1px solid ;
}
.details-border{
	border-bottom: 1px solid #eee;
	margin-top: 10px;
}
.details-pad-bot{
	padding-bottom: 10px;
}
.block{
	display: block;
	color: #9b9b9b;
}
.details-pad-top-n{
	padding-top:0px !important;
}
.details-padding{
	padding-left : 5px !important;
	padding-right : 5px !important;
}
.details-title{
	font-size: 15px;
	color: #3e3e98;
}
.details-title-col{
	color: #3e3e98 !important;
}
.details-dish-title{
	font-size: 14px;
	color: #9b9b9b;
}
.details-dish-con{
	margin: 10px 0px; 
}
.details-fl-left{
	float: left; 
}
.details-add-btn{
	float: right;
    margin-top: -30px;
    border: 1px solid #3e3e98;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    color: #3e3e98;
	font-size: 13px;
}
.order-price{
	float: right;
	
	font-size: 13px;
}
.order-image{
	width: 20%;
    margin-right: 10px;
	float:left;
}
.order-border-t{
	border-top: 1px solid #eee;
    padding-top: 5px !important;
	padding-bottom: 5px !important;
}
.order-m-b{
	margin-bottom: 5px;
}
.order-button{
	background: #3e3e98;
    width: 90%;
    text-shadow: none;
    border: none;
    padding: 10px 0;
    font-size: 15px;
	letter-spacing: 2px;
    text-transform: uppercase;
}
.order-btn{
	padding : 0 20px;
	text-align: center;
	margin-top: 20px;
}
.order-image .bgimg{
    position: relative;
    width: 100%;
    height: 45px;
    background-color: rgba(40, 44, 63, 0.05);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
	margin-bottom:10px;
}
.order-conf{
	float: left;
    padding: 0 10px;
    font-size: 12px;
    
}
.order-conf2{
	 
    display: block;
    text-align: center;
    padding-right: 10px;
}
.left{
	text-align: left;
	margin-bottom: 20px;
    margin-top: 10px;
}
.bold{
	font-weight:600px;
}
.add-title{
	font-size: 14px;
    text-transform: uppercase;
    color: #454950 !important
}
.add-back{
	padding : 0 10px;
}
.add-edit{
	text-align: center;
    color: #3e3e98 !important;
    font-weight: 500;
    padding: 2px 10px !important;
    border: 1px solid #3e3e98;
    border-radius: 5px;
    margin-right: 10px;
    float: right;
    margin-top: 10px;
	font-size: smaller;
}
.add-details{
	display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    padding: 10px 0px;
    background-color: #fff;
    position: relative;
    margin: 0 0px 0px;
    border-radius: 0px;
	border-bottom : 1px solid #eee;
}
.add-button{
	background: none;
    width: 90%;
    text-shadow: none;
    border: none;
    padding: 10px 0;
    font-size: 15px;
	letter-spacing: 2px;
    text-transform: uppercase;
	color:#3e3e98;
	border:1px solid #3e3e98;
	font-weight:600;
}


.accordion {
    background-color: #fff;
    color: #9b9b9b;
    cursor: pointer;
    padding: 18px 0;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 14px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
	border-bottom: 1px solid #eee;
}
.details-color{
	color:#454950 !important;
	font-weight: 500;
}

.accordion:hover {
  background-color: #fff;
}

.accordion:after {
  content: '\002B';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.accordion.active:after {
  content: "\2212";
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

input,
textarea {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 10px;
}

input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group {
  clear: both;
  margin: 0px 0;
  position: relative;
  flex-wrap: initial;
}

.input-group input[type='button'] {
  background-color: #3e3e98;
  min-width: 30px;
  width: auto;
  transition: all 300ms ease;
}

.input-group .button-minus,
.input-group .button-plus {
  font-weight: bold;
  height: 25px;
  padding: 0;
  width: 30px;
  position: relative;
}

.input-group .quantity-field {
  position: relative;
  height: 25px;
  left: -6px;
  text-align: center;
  width: 62px;
  display: inline-block;
  font-size: 13px;
  margin: 0 0 5px;
  resize: vertical;
}

.button-plus {
  left: -13px;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #3e3e98;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 4px;
	left: 4px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: white;
}
.thank img{
width: 30%;
}
.thank{
	font-size: 20px;
    color: #0eb90e;
    font-weight: 600;
}
.order-bot-mar{
    margin-bottom : 0px !important ;
	margin-top : 0px !important ;
}
.order-title{
    font-size: 12px;
    color: #9b9b9b;
	}
.track-min-height img{
	vertical-align: top;
}
.line{
    margin-left: -20px;
    margin-top: 10px;
}
.m_top_m
{
    margin-top: -1px;
}	
.m_top_m_1
{
    margin-top: -6px;
}
.mt-10{
margin-top : 10px;
}
.mt-15 {
    margin-top: 15px;
}
.bgimg{
border-radius : 5px;
}
.wrap-login200 {
    
    border-radius: 10px;
    overflow: hidden;
    //background: #3e3e98 !important;
}
.color{
color: #3e3e98 !important;
}
.form-group-1.float-label-1 .form-control-1:focus {
    border-bottom:2px solid rgb(62, 62, 152);
    color: #000 !important;
}
.form-group-1.float-label-1 .form-control-1{
color: #000 !important;
border-bottom: 2px solid rgb(62, 62, 152);
position: relative;
    z-index: 1;
    background: none;
    padding: 0.313rem 0rem;
    border-width: 0 0 1px 0;
    border-radius: 0;
    -webkkit-borde-radius: 0;
    font-size: 16px;
    -webkit-transition: ease all .5s;
    transition: ease all .5s;
    
}
.form-control-1 {
    /* display: block; */
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-group-1.float-label-1 .form-control-1.active + label, .form-group-1.float-label-1 .form-control-1:focus + label {
    top: 0;
    left: 0;
    font-size: 13px;
    opacity: 1;
}
.form-group-1.float-label-1 label {
    color: #fff;
    -webkit-transition: ease all .2s;
    transition: ease all .2s;
    line-height: 20px;
    position: absolute;
    top: 27px;
    left: 0;
    font-size: 16px;
    opacity: 1;
    z-index: 0;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.form-group-1.float-label-1 {
    margin-bottom: 20px;
    position: relative;
    padding-top: 20px;
}	
.tag{
font-size: 16px;
}
.mar-right{
margin-right : 20px;
}
.collection-banner .absolute-contain {
    position: absolute;
    background-color: #fff;
    bottom: -22px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 20px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.35);
    min-width: 85%;
    text-align: center;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}
.collection-banner {
    overflow: unset !important;
}
.collection-banner {
    position: relative;
    overflow: hidden;
}
.text-left {
    text-align: left!important;
}
.collection-banner img {
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    width: 100%;
}
.img-fluid, .img-thumbnail {
    max-width: 100%;
    height: auto;
}
.collection-banner .absolute-contain h3 {
    color: #3e3e98;
    text-transform: capitalize;
    margin-top: -5px;
    font-weight: 700;
	font-size: 24px;
	letter-spacing: .03em;
}
.collection-banner .absolute-contain h4 {
    color: #000;
    margin-bottom: 0;
	font-size: 16px;
	text-transform: capitalize;
    line-height: 1;
}
.mt-40{
margin-top : 40px;
}
.mb-20{
margin-bottom:20px;
}


/* --Model css-- */


.modal-header {
    padding: 6px 16px;
    background-color: #3e3e98;
    color: white;
    margin: auto;
    width: 80%;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.4s;
    display: block;
}


.modal-body {
padding: 2px 16px;
}


.modal-footer {
  padding: 2px 16px;
  background-color: #3e3e98;
  color: white;
  margin: auto;
   width: 80%;
   position: relative;
   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
   animation-name: animatetop;
   animation-duration: 0.4s;
   display: block;
   text-align: right;
}


.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0 15px;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 0;
}


@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}
.modal {
    position: fixed;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}
.red{
background-color: white;
}
.col-black{
color : black !important;
}
.blue{
    background: #3e3e98;
}
.mdl-btn{
	line-height: 15px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    border-radius: 0;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    margin: 5px 0;
	border-radius: 8px;
}
.tag option,.tag button, select{
font-size: 14px;
color: #3e3e98 !important;
}
.gym-pad-bot{
padding-bottom: 30px;
}
.gym-green{
color : #0eb90e;
}
.slick-track{
opacity: 1; transform: translate3d(-2401px, 0px, 0px); width: 6860px;
}
.slick-slide{
width: 343px;
}



